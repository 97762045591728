@import '../../styles/breakpoints';
@import '../../styles/dimensions';

.disclaimers-container {
    padding-bottom: $gap-larger;
}

.global {
    list-style: none;
    margin-bottom: 1em;
    padding-left: 0;

    li {
        margin-bottom: 0.5em;
        position: relative;
        word-wrap: break-word;
    }
}

.disclaimers {
    counter-reset: disclaimer;
    list-style: none;
    margin-top: 0;
    padding-left: 1em;

    li {
        counter-increment: disclaimer;
        margin-bottom: 0.5em;
        position: relative;
        word-wrap: break-word;
    }

    li::before {
        content: counter(disclaimer) ': ';
        left: -1.5em;
        position: absolute;
        top: 0;
    }

    span {
        font-weight: 400;
    }
}
